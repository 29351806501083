const LS_TOKEN = 'USER_TOKEN';

export function getAccessToken(){
	return localStorage.getItem(LS_TOKEN) ? localStorage.getItem(LS_TOKEN) : sessionStorage.getItem(LS_TOKEN);
}

export function setAccessToken(token, type = 'SESSION'){
	if (type === 'SESSION'){
		sessionStorage.setItem(LS_TOKEN, token);
	}
	else if (type === 'LOCAL') {
		localStorage.setItem(LS_TOKEN, token);
	}
}

export function removeAccessToken(){
	localStorage.removeItem(LS_TOKEN);
	sessionStorage.removeItem(LS_TOKEN);
}