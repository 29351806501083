import {React, useEffect,} from 'react';
import { LockClosedIcon, } from '@heroicons/react/solid/esm';
import { onLogin, } from '../../actions/userActions.js';
import { connect, } from 'react-redux';
import { useNavigate, } from 'react-router-dom';
import { setAccessToken, } from '../../utils/StorageAccess.js';
import logo from '../../../assets/logo_flipside.png';

function Login(props) {
	let navigate = useNavigate();
	const {userData, } = props;
	const { loggingInError, } = userData;

	useEffect(()=> {
		if (userData.isLoggedIn) {
			navigate('/');
		}
	}, [userData]);

	let handleFormSubmit= event => {
		event.preventDefault();
		const {login,} = props;
		const form = event.target;
		const data = new FormData(form);
		const reqData = {};
		for (let name of data.keys()) {
			const input = form.elements[name];
			reqData[name] = input.value;
		}
		const loginData = {
			identifier: reqData.email,
			password: reqData.password,
		};

		login(loginData, token => {
			if(reqData['remember-me']){
				setAccessToken(token, 'LOCAL');
			}
			else{
				setAccessToken(token, 'SESSION');
			}
		});
	};

	return (
		<div className="min-h-full flex items-center justify-center py-32 px-4 lg:px-8">
			<div className="max-w-md w-full space-y-8">
				<div className={ 'mb-8' }>
					<img
						className={ 'w-32 mx-auto' }
						src={ logo }
					/>
					<h2 className="mt-6 text-center text-3xl font-bold">Sign in to your LYN account</h2>
				</div>
				{ loggingInError.length > 0 &&
					<div className={ 'loginError' }>Login failed! <br/> Invalid email address or password!</div>
				}
				<form
					className="space-y-6"
					action="#"
					method="POST"
					onSubmit={ handleFormSubmit }>
					<input
						type="hidden"
						name="remember"
						defaultValue="true" />
					<div className="rounded-md shadow-sm -space-y-px">
						<div>
							<label
								htmlFor="email-address"
								className="sr-only">
								Email address
							</label>
							<input
								id="email-address"
								name="email"
								type="email"
								autoComplete="email"
								required
								className={ 'loginInput rounded-t-md' }
								placeholder="Email address"
							/>
						</div>
						<div>
							<label
								htmlFor="password"
								className="sr-only">
								Password
							</label>
							<input
								id="password"
								name="password"
								type="password"
								autoComplete="current-password"
								required
								className={ 'loginInput rounded-b-md ' }
								placeholder="Password"
							/>
						</div>
					</div>
					<div className="flex items-center justify-between">
						<div className="flex items-center cursor-pointer">
							<input
								id="remember-me"
								name="remember-me"
								type="checkbox"
								className="h-4 w-4 text-darkBlue focus:ring-darkBlue border-gray-300 rounded"
							/>
							<label
								htmlFor="remember-me"
								className="ml-2 block text-sm cursor-pointer">
								Remember me
							</label>
						</div>
						{/*<div className="text-sm">
							<a
								href="#"
								className="font-medium text-tintedBlue hover:text-darkBlue">
								Forgot your password?
							</a>
						</div>*/}
					</div>
					<div>
						<button
							type="submit"
							className={ 'loginButton' }
						>
							<span className="absolute left-0 inset-y-0 flex items-center pl-3">
								<LockClosedIcon
									className="h-5 w-5 text-lightPink group-hover:text-white"
									aria-hidden="true" />
							</span>
							Sign in
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

const actionCreators = {
	login: onLogin,
};

const mapStateToProps = state => ( {
	userData: state.user,
} );

export default connect(mapStateToProps, actionCreators)(Login);