import React, { PureComponent, } from 'react';

class PageLoader extends PureComponent {
	render() {
		return (
			<main className="page-loader-main">
				<div className="lds-spinner">
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
					<div/>
				</div>
				<p>Page is loading</p>
			</main>
		);
	}
}

export default PageLoader;