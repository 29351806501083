import cloneDeep from 'lodash/cloneDeep';

export default function userReducer(state = cloneDeep(defaultState), action = {}) {
	let newState = cloneDeep(state);
	switch(action.type) {
		case 'ON_LOGIN': {
			newState.loggingIn = true;
			newState.loggingInError = '';
			return newState;
		}
		case 'ON_LOGIN_FULFILLED': {
			newState.loggingIn = false;
			newState.isLoggedIn = true;
			return newState;
		}
		case 'ON_LOGIN_REJECTED': {
			const { error, } = action.payload;
			newState.loggingIn = false;
			newState.loggingInError = error;
			return newState;
		}
		case 'TOKEN_VALIDATED': {
			const { user, } = action.payload;
			newState.isLoggedIn = true;
			newState.userData = user;
			newState.loggingIn = false;
			return newState;
		}
		case 'TOKEN_NOT_AUTHORIZED': {
			newState.isLoggedIn = false;
			newState.loggingIn = false;
			return newState;
		}
		case 'SET_TOKEN': {
			const { token, } = action.payload;
			newState.token = token;
			return newState;
		}
		case 'RESET_LOGIN': {
			newState.loggingIn = false;
			newState.loggingInError = false;
			return newState;
		}
		case 'ON_LOGOUT': {
			return cloneDeep(defaultState);
		}
		default:
			return state;
	}
}

const defaultState = {
	token: '',
	userData: {},
	isLoggedIn: false,
	loggingIn: true,
	loggingInError: '',
};