import cloneDeep from 'lodash/cloneDeep';

export default function panelReducer(state=cloneDeep(defaultState), action = {}) {
	let newState = cloneDeep(state);
	switch(action.type) {
		case 'ON_PANEL_CHANGE': {
			const { panelTitle, } = action.payload;
			newState.panelTitle = panelTitle;
			return newState;
		}
		case 'ON_PENDING_COUNT_SET': {
			const { pendingPOCount, } = action.payload;
			newState.pendingPOCount = pendingPOCount;
			return newState;
		}
		case 'ON_PENDING_COUNT_DECREMENT': {
			if(!state.pendingPOCount <= 0) newState.pendingPOCount -= 1;
			return newState;
		}
		case 'RESET_EVERYTHING': {
			return cloneDeep(defaultState);
		}
		default:
			return state;
	}
}

const defaultState = {
	panelTitle: '',
	pendingPOCount: 0,
};