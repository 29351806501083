import './wdyr.js';

import React from 'react';
import ReactDOM from 'react-dom';
import MainApp from './javascript/MainApp.jsx';
import { Provider, } from 'react-redux';
import { BrowserRouter, } from 'react-router-dom';
import generateNewStore from './javascript/store.js';
import './tailwind.css';

const store = generateNewStore();
const rootElement = document.getElementById('root');

// Check if service workers are supported
if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register('/serviceWorker.js', {
		scope: '/',
	});
}

if (rootElement.hasChildNodes()) {
	ReactDOM.hydrate(
		<Provider store={ store }>
			<BrowserRouter>
				<MainApp/>
			</BrowserRouter>
		</Provider>,
		rootElement
	);
} else {
	ReactDOM.render(
		<Provider store={ store }>
			<BrowserRouter>
				<MainApp/>
			</BrowserRouter>
		</Provider>,
		rootElement
	);
}