import { applyMiddleware, createStore, } from 'redux';
import { rootReducer, } from './rootReducer.js';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

let store;
const middleWares = [thunk, logger];

if (process.env.NODE_ENV === 'development') {
	const { composeWithDevTools, } = require('redux-devtools-extension');
	store = () => {
		return createStore(
			rootReducer(),
			composeWithDevTools(
				applyMiddleware(...middleWares)
			)
		);
	};
} else {
	store = () => {
		return createStore(
			rootReducer(),
			applyMiddleware(thunk)
		);
	};
}

export default store;