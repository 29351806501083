import cloneDeep from 'lodash/cloneDeep';

export default function spreadsheetReducer(state=cloneDeep(defaultState), action = {}) {
	let newState = cloneDeep(state);

	switch(action.type) {
		case 'ON_CLIENTS_SET': {
			const { clients, } = action.payload;
			newState.clients = clients;
			return newState;
		}
		case 'ON_SUPPLIERS_SET': {
			const { suppliers, } = action.payload;
			newState.suppliers = suppliers;
			return newState;
		}
		default:
			return state;
	}
}
const defaultState = {
	clients: [],
	suppliers: [],
};