import { combineReducers, } from 'redux';
import userReducer from './reducers/userReducer.js';
import panelReducer from './reducers/panelReducer.js';
import spreadsheetReducer from './reducers/spreadsheetReducer.js';
import searchReducer from './reducers/searchReducer.js';

export const rootReducer = () => combineReducers({
	user: userReducer,
	panel: panelReducer,
	spreadsheet: spreadsheetReducer,
	searchOptions: searchReducer,
});