import { createError, fetch, } from '../utils/utils.js';
import { calls, } from '../utils/calls.js';
import { API_URL, } from '../../env.js';
import { removeAccessToken, } from '../utils/StorageAccess.js';
import pick from 'lodash/pick';

const USER_PROPERTIES_ARRAY = ['id', 'user_name', 'user_surname', 'email', 'requestor_auto_approve_limit', 'role'];

/**
 * Login a user
 * @param data {Object}
 * @param successCallback {Function}
 * @returns {Function}
 */
export const onLogin = (data, successCallback = null) => {
	return dispatch => {
		dispatch({ type: 'ON_LOGIN', });
		fetch(calls.onLogin(API_URL, data))
			.then(res => {
				if(res?.user?.role?.type !== 'requestor' && res?.user?.role?.type !== 'financial' && res?.user?.role?.type !== 'approver'){
					throw new Error('Only requestor, financial and approver users are allowed to login in this platform. Please contact the administrator.');
				}
				if(res?.user?.isSupplier){
					throw new Error('Supplier users are not allowed to login in this platform. Please contact the administrator.');
				}
				if (successCallback) {
					successCallback(res.jwt);
				}
				dispatch({
					type: 'ON_LOGIN_FULFILLED',
				});
				dispatch({
					type: 'TOKEN_VALIDATED',
					payload: {
						user: pick(res.user, USER_PROPERTIES_ARRAY),
					},
				});
				dispatch({
					type: 'SET_TOKEN',
					payload: {
						token: res.jwt,
					},
				});
			})
			.catch(err => {
				dispatch({
					type: 'ON_LOGIN_REJECTED',
					payload: { error: createError(err), },
				});
			});
	};
};

/**
 * Validate a user's token
 * @param token {Object}
 * @param dispatch {Function}
 * @returns {Promise}
 */
export const validateToken = (token, dispatch) => {
	return new Promise(() => {
		fetch(calls.validateToken(API_URL, token))
			.then(res => {
				dispatch({ type: 'TOKEN_VALIDATED',
					payload: {
						user: pick(res, USER_PROPERTIES_ARRAY),
					},
				});
				dispatch({
					type: 'SET_TOKEN',
					payload: {
						token: token,
					},
				});
			})
			.catch(() => {
				removeAccessToken();
				dispatch({
					type: 'TOKEN_NOT_AUTHORIZED',
				});
			});
	});
};

export const onLogout = () => {
	return dispatch => {
		removeAccessToken();
		dispatch({
			type: 'ON_LOGOUT',
		});
		window.location.replace('/login');
	};
};