module.exports = {
	'WEB_URL': 'https://lyn.flipsidegroupstudio.com',
	'API_URL': 'https://lyn-api.flipsidegroupstudio.com',
	'STATIC_ASSETS_CDN': 'https://dlyzd14hq029d.cloudfront.net',
	'CLIENT_EMAIL': 'read-google-sheets@quick-platform-357711.iam.gserviceaccount.com',
	'PRIVATE_KEY': '-----BEGIN PRIVATE KEY-----\\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCuqZMotXlKDwFi\\ne9FOWZHUD7WPKagRzD5MdV4ZJLEYwZhvsjJf48I2oCQYL/R7lO4MOijKvmH2Gfpb\\n4FQUyePhyT8futwT6dg4CzafiCF6n3dD4Sj3N1ZYlBsFiOOwKAcY8XWRLVKQzbOH\\n8AaABcZVJgJS0dNjVLTgKXUABfBn1zslTcRNNio7BBDUPA+5wGcXQhNrA0DNTUnX\\n6RNn8XVlWaNxocYU/mZL9Pbxyrn1Gfw0OrPzDigoHIdJHd4awCrKNHxyvAc4c5wL\\nBqIwKwuSmAng7qnpWDhT4aGTfWRNEx3tWPNGWfA+jYkRb9fmX2i2QWFZupr21cqv\\ntvUh3exlAgMBAAECggEADKwN8AwBPEUhbxaXpXfB06ETevW6lWv8Rcoqz70wl3YN\\noWZMLwEpyT0je+5tblKXAM5uwOLriDzzQ/DfI3ulq8R7mXQ9B7mAGp9++40XYhD+\\nyK8l1zstvxMjxhTb8XRoq2pyrVvx8Rfo2MnVLDr/BuomjKwKNQXjcki1DebgaHrU\\nzFNiE7wmw37qx8U6QWCeXngrO1el0VwUYB920LB/Qb59Z2IQEPWwxvoGIoYjqrXC\\n+nq2csuleesTuW+fcGLlAYCe2dx2JUrGRUdVhONT6o1OSq82YfXK7urKzfzhnGA1\\noOhrK4xzGB3MFx7kq1fi4887hOLtoEN7Id06RmiUMQKBgQDpLQNBESDUpnZ6fnqy\\n00isaJxYwBJ8Ol/lncqEZzXsWWWVJAezYGUp62R/Ge4DRkS6EwqSELa9RJvQJwsN\\nulhTefiQwYyPtDi6c7wkiU/R5pkQQheveHFqba7TTZCOXdI4Ag2taa6f2gqseQVb\\nN623zjHxDbB7jUrMKnjl9tWY2QKBgQC/wlDTfFses1JkrzgNOrvnfYHllifh468M\\n1XlmHwAvJd0vElNrejRM00WmygBmM0h+GnTqsqANCstLQUzHh0TjBSpXQaFR7np9\\nX7/a2IOVmeA5EkW7gHX6vJFq4zDLWaRXHH4DSmgRuChKsYrLGLYA7dUhV+Mdb9GK\\nBOeMhKOYbQKBgAVLUFeZ2/T4EpiVZpPI2E2R0nrxYXoQmTzNaFu5PDAxl2zbdJFO\\nDv30qkN4gz7vyX+wDv1iA8QoWqFPQaD+KiM7Ebm3q0lX/jG9jvgFZhqN8cu8tGz3\\nfxu7gcyqZi3VU4CSC110mNJoUPW5ibWYfgk+VUPz253yh1A7ZqNSB2eBAoGBALZ+\\nFTHPrWAgRI9xeaSNevoppY97QWVmq9dI6gnYAvLb1A3SIPWuDUv33NeIcqzQrXLl\\ndtNNP6hp12M9N/bDszliVxNHcGFq1cOvdgSt/TxIfu2RiC+TZ7RB9RqGMI+2NgJ5\\nKSu0iioi2qWLJY13bO6haS4cWBZRrlbtp/dA2/hZAoGBAOj3epFG1yR+9qsRkPRs\\nvsAhXH0AHAdGs8KwHl4x/Yxd6BObp0ftnFfYNhoTx58sJLlFCusOqTDuSsLT0bt8\\nxMh250HFib8uUwzOgKvdQg6bTwE8phXm3iSQxpdrxNRZCyUVQkUtclzAGPzrkdsO\\nDP4+rAANWnj+R8C2JXimXTsX\\n-----END PRIVATE KEY-----\\n',
	'SUPPLIER_SPREADSHEET_ID': '1q3GKmcBWyBd5k21kfdoWuGZ-DWDC-xFeERpp-hnQV0o',
	'SUPPLIER_SHEET_ID': '0',
	'CLIENT_SPREADSHEET_ID': '1tuPWOfn8MUaWAx6mqhlqc7NtdmT6_ro_7NYwJmm3Ryw',
	'CLIENT_SHEET_ID': '1394205894',
	'EXPORT_SPREADSHEET_ID': '1ANXTyEiPMT6S2boQF4jsuW9Eg9r017jRgRRwmx8FlzQ',
	'PUBLIC_VAPID_KEY': 'BIwuyUSVys-Oy5MMWtf3f-scfityj_rKD0swtC71zWAWo62dUxi5XEMSboaHEmxmViV_ZfRd4IsViteuOFRiqtI',
	'ENVIRONMENT': 'production',
};
