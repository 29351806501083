import React, { PureComponent, } from 'react';
import omit from 'lodash/omit.js';

class RegularRoute extends PureComponent {
	render() {
		const { Component, } = this.props;
		const routeProps = omit(this.props, ['children', 'loggedIn', 'Component']);

		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<Component { ...routeProps }/>
		);
	}
}

export default RegularRoute;