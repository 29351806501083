/**
 * Regular JSON Headers - no authentication
 * @param language {String}
 * @returns {{'Content-type': {String}, Accept: {String}, 'Content-Language': {String}}}
 */

const getJSONHeaders = (language = 'en') => ( {
	'Content-type': 'application/json',
	'Accept': 'application/json',
	'Content-Language': language,
} );

/**
 * JSON Header with authentication (if provided), otherwise just JSON Headers and the request will probably throw 401
 * @param token {String}
 * @param language {String}
 * @returns {{Authorization: {String}, 'Content-type': {String}, Accept: {String}, 'Content-Language': {String}}|{'Content-type': {String}, Accept: {String}, 'Content-Language': {String}}}
 */
const getAuthenticatedHeaders = (token = null, language = 'en') => {
	if (!token) {
		return {
			'Content-type': 'application/json',
			'Accept': 'application/json',
			'Content-Language': language,
		};
	}
	return {
		'Content-type': 'application/json',
		'Accept': 'application/json',
		'Content-Language': language,
		'Authorization': `Bearer ${ token }`,
	};
};

export const calls = {
	onLogin: (service, data) => ( {
		url: `${ service }/auth/local`,
		method: 'POST',
		headers: getJSONHeaders(),
		body: data,
	} ),
	validateToken: (service, token) => ( {
		url: `${ service }/users/me`,
		method: 'GET',
		headers: getAuthenticatedHeaders(token),
	} ),
	onIssuePO: (service, data, token) => ({
		url: `${ service }/purchase-orders/issue-po`,
		method: 'POST',
		headers: getAuthenticatedHeaders(token),
		body: data,
	}),
	onGetMYPOs: (service, userId, start, limit, token) => ({
		url: userId === null ? `${ service }/purchase-orders?_sort=created_at:DESC&_start=${start}&_limit=${limit}` :`${ service }/purchase-orders?po_issuer=${userId}&_sort=created_at:DESC&_start=${start}&_limit=${limit}`,
		method: 'GET',
		headers: getAuthenticatedHeaders(token),
	}),
	onGetMYPOsFiltered: (service, userId, start, limit, token, searchString) => ({
		url: userId === null ? `${ service }/purchase-orders?_sort=created_at:DESC&_start=${start}&_limit=${limit}${searchString}` :`${ service }/purchase-orders?po_issuer=${userId}&_sort=created_at:DESC&_start=${start}&_limit=${limit}${searchString}`,
		method: 'GET',
		headers: getAuthenticatedHeaders(token),
	}),
	onGetJobRelatedPOs: (service, token, searchString) => ({
		url: `${ service }/purchase-orders?_sort=created_at:DESC&${searchString}`,
		method: 'GET',
		headers: getAuthenticatedHeaders(token),
	}),
	onGetExportPOs: (service, startDate, endDate, token) => ({
		url: `${ service }/purchase-orders?po_request_date_gte=${startDate}&po_request_date_lte=${endDate}&_limit=-1`,
		method: 'GET',
		headers: getAuthenticatedHeaders(token),
	}),
	onGetPendingPOs: (service, token) => ({
		url: `${ service }/purchase-orders/pending`,
		method: 'GET',
		headers: getAuthenticatedHeaders(token),
	}),
	onRequestorEditPO: (service, data, token, POId) => ({
		url: `${ service }/purchase-orders/requestor-edit/${POId}`,
		method: 'PUT',
		headers: getAuthenticatedHeaders(token),
		body: data,
	}),
	onFinancialEditPO: (service, data, token, POId) => ({
		url: `${ service }/purchase-orders/finance-edit/${POId}`,
		method: 'PUT',
		headers: getAuthenticatedHeaders(token),
		body: data,
	}),
	onApproverAnalyzePO: (service, data, token, POId) => ({
		url: `${ service }/status-changes/set-initial-approval/${POId}`,
		method: 'POST',
		headers: getAuthenticatedHeaders(token),
		body: data,
	}),
	onFinancialAnalyzePO: (service, data, token, POId) => ({
		url: `${ service }/status-changes/set-financial-approval/${POId}`,
		method: 'POST',
		headers: getAuthenticatedHeaders(token),
		body: data,
	}),
	onFindPO: (service, token, POId) => ({
		url: `${ service }/purchase-orders/${POId}`,
		method: 'GET',
		headers: getAuthenticatedHeaders(token),
	}),
	onRequestorFinancialDeletePO: (service, token, POId) => ({
		url: `${ service }/purchase-orders/${POId}`,
		method: 'DELETE',
		headers: getAuthenticatedHeaders(token),
	}),
	onFinancialDonePO: (service, token, POId) => ({
		url: `${ service }/status-changes/set-done/${POId}`,
		method: 'POST',
		headers: getAuthenticatedHeaders(token),
	}),
	onRequestorDuplicatePO: (service, token, POId) => ({
		url: `${ service }/purchase-orders/duplicate-po/${POId}`,
		method: 'POST',
		headers: getAuthenticatedHeaders(token),
	}),
	onGetRequesters: (service, token) => ({
		url: `${ service }/filters-service/requestors`,
		method: 'GET',
		headers: getAuthenticatedHeaders(token),
	}),
	onGetSuppliers: (service, token) => ({
		url: `${ service }/filters-service/suppliers`,
		method: 'GET',
		headers: getAuthenticatedHeaders(token),
	}),
	onWebPushSubscribe: (service, data, token) => ({
		url: `${ service }/web-push/subscription`,
		method: 'POST',
		headers: getAuthenticatedHeaders(token),
		body: data,
	}),
	onWebPushUnsubscribe: (service, token) => ({
		url: `${ service }/web-push/unsubscribe`,
		method: 'POST',
		headers: getAuthenticatedHeaders(token),
	}),
};