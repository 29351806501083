import * as unfetch from 'isomorphic-unfetch';
import cookie from 'js-cookie';
import { API_URL, CLIENT_EMAIL, PRIVATE_KEY, STATIC_ASSETS_CDN, } from '../../env.js';
import { calls, } from './calls.js';
import { getAccessToken, } from './StorageAccess.js';
import { GoogleSpreadsheet, } from 'google-spreadsheet';

const _ = require('lodash');

/**
 * Get a device's type from the user-agent in the request
 * @param userAgent {String}
 * @returns {Object}
 */
export const getDeviceType = userAgent => {
	if (userAgent) {
		let $ = {};
		$.mobile = /Android ([0-9.]+)[);]/.test(userAgent) || /iPhone/.test(userAgent) || /mobile/i.test(userAgent) || /webOS\/([0-9.]+)[);]/.test(userAgent);
		$.tablet = /iPad/.test(userAgent);
		$.desktop = /(Intel|PPC) Mac OS X/.test(userAgent) || /Windows NT ([0-9._]+)[);]/.test(userAgent);
		return $;
	}
	return {};
};

/**
 * Our custom fetch function
 * @param props {Object}
 * @param skipJSON {Boolean}
 * @param fetchSignal {Object}
 * @returns {Function}
 */
export const fetch = (props, skipJSON = false, fetchSignal = null) => {
	let ok;
	const { url, } = props;
	const methodHeadersBody = { ...props, };
	delete methodHeadersBody.url;
	const returnMethodHeadersBody = () => {
		if (methodHeadersBody.body) {
			return methodHeadersBody.body;
		}
		return null;
	};
	return unfetch(url, {
		...methodHeadersBody,
		body: methodHeadersBody.body && !skipJSON ? JSON.stringify(methodHeadersBody.body) : returnMethodHeadersBody(),
		signal: fetchSignal ? fetchSignal : null,
	})
		.then(res => {
			ok = res.ok;
			return res.json();
		})
		.then(res => {
			if (ok) {
				return res;
			} else {
				// In development log the request that failed
				if (process.env.NODE_ENV === 'development') {
					// eslint-disable-next-line no-console
					console.log(res);
					// eslint-disable-next-line no-console
					console.log(props);
				}
				return Promise.reject(res);
			}
		});
};

/**
 * Creates an error avoiding app crashes in case of Network Errors
 * @param err {Object}
 * @param customMessage {String}
 * @returns {String}
 */
export const createError = (err, customMessage = null) => {
	let message = 'Something went wrong. Please contact technical support. Code 500';
	if (err && ( err.statusText || err.message || err.error || err.errors )) {
		message = err.error || err.statusText || err.message || customMessage;
	}

	if (process.env.NODE_ENV === 'development') {
		// eslint-disable-next-line no-console
		console.log(err);
		if (err?.code) {
			// eslint-disable-next-line no-console
			console.log('err.code', err.code);
		}
		if (err?.name) {
			// eslint-disable-next-line no-console
			console.log('err.name', err.name);
		}
	}

	return message;
};

/**
 * Sets a cookie
 * @param field {String}
 * @param value {String}
 * @param expires {Date}
 */
export const setCookie = (field, value, expires = null) => {
	cookie.set(field, value, expires ? { expires: expires, } : {});
};

/**
 * Retrieves a cookie
 * @param name {String}
 * @returns {String}
 */
export const getCookie = (name = null) => cookie.get(name);

/**
 * Deletes a cookie
 * @param field {String}
 */
export const removeCookie = field => {
	cookie.remove(field);
};

/**
 * Gets a cookie expire time - used for setting the expire time
 * @param number
 * @param metric
 */
export const getCookiesExpireTime = (number, metric) => {
	let expires = new Date();
	if (metric === 'hour' || metric === 'hours' || metric === 'h') {
		expires.setTime(expires.getTime() + number * 60 * 60 * 1000);
	} else if (metric === 'day' || metric === 'days' || metric === 'd') {
		expires.setTime(expires.getTime() + number * 24 * 60 * 60 * 1000);
	}
};

/**
 * Deletes empty values from an object
 * @param obj {Object}
 * @param excluded {Array}
 */
export const deleteUnusedProperties = (obj, excluded = []) => {
	let keysToDelete = [], copy = { ...obj, };
	Object.keys(copy).forEach(key => {
		if (!excluded.find(excludedField => excludedField === key) && !copy[key] && parseInt(copy[key]) !== 0 && copy[key] !== false && copy[key] !== 'false') {
			keysToDelete.push(key);
		}
	});
	keysToDelete.forEach(key => {
		delete copy[key];
	});
	return copy;
};

/**
 * Get all tokens required for API calls
 * @param cookies {Object}
 * @return {Object}
 */
export const getTokens = (cookies = getCookie()) => {
	const { token, } = cookies || {};
	return {
		token: token,
	};
};

/**
 * Transforms an object into a query string
 * @param object {Object}
 */
export const buildQueryStringFromObject = object => {
	let query = '';
	let previouslyHasAProperty = false;
	Object.keys(object).forEach(key => {
		if (object[key] !== undefined && object[key] !== null) {
			if (previouslyHasAProperty) {
				query += '&';
			} else {
				query += '?';
			}
			previouslyHasAProperty = true;
			if (Array.isArray(object[key])) {
				object[key].forEach((element, i) => {
					query += `${ key }[${ i }]=${ encodeURIComponent(element) }${ i !== object[key]?.length - 1 ? '&' : '' }`;
				});
			} else {
				query += `${ key }=${ encodeURIComponent(object[key]) }`;
			}
		}
	});

	return query;
};

/**
 * Generates a formData object for requests with files to upload
 * @param {String} mediaFieldName
 * @param {Object} sourceObject - object with the images and other properties
 * @param {String} filesKey - key for the files in the sourceObject
 * @param {Object} options - { ignoredKeys: [] }
 * @returns {FormData}
 */
export const generateFormData = (mediaFieldName = 'media', sourceObject, filesKey, options) => {
	let formData = new FormData();
	if (sourceObject[filesKey] && !Array.isArray(sourceObject[filesKey])) {
		formData.append(mediaFieldName, sourceObject[filesKey], sourceObject[filesKey]?.name);
	} else if (sourceObject[filesKey]) {
		sourceObject[filesKey]?.forEach(file => {
			formData.append(mediaFieldName, file, file?.name);
		});
	}

	Object.keys(sourceObject).forEach(key => {
		if (key !== filesKey && !( options?.ignoredKeys?.find(k => k === key) ) && typeof sourceObject[key] !== 'undefined') {
			formData.append(key, Array.isArray(sourceObject[key]) ? JSON.stringify(sourceObject[key]) : sourceObject[key].toString());
		}
	});

	return formData;
};

/**
 * General way to display data
 * @param value {String|Number}
 * @param placeholder
 * @returns {string}
 */
export const displayValueOrPlaceholder = (value, placeholder = '-') => {
	return value || placeholder;
};

/**
 * Capitalizes a word
 * @param word {String}
 * @return {String}
 */
export const capitalizeWord = word => word?.charAt(0)?.toUpperCase() + word?.slice(1);

/**
 * Get language sub path
 * @param language {String}
 * @returns {String}
 */
export const getLanguageSubPath = language => {
	if (language === 'en') {
		return '';
	}
	return `/${ language }`;
};

/**
 * Prevents the default action the browser makes on the event.
 */
export const preventDefault = e => e?.preventDefault();

/**
 * Prevents further propagation of current event in the capturing and bubbling phases.
 */
export const stopPropagation = e => e?.stopPropagation();

/**
 * Use this function when you don't want to return anything but you need to like for example the .catch(() => {}).
 */
// eslint-disable-next-line no-console
export const placeholderFunction = parameter => process.env.NODE_ENV === 'development' ? console.log(parameter) : null;

/**
 * Returns current year in YYYY format as String.
 */
export const getCurrentYear = () => new Date().getFullYear().toString();

/**
 * Add the media location prefix to images
 * @param url {String}
 * @returns {String}
 */
export const addMediaPrefix = url => `${ STATIC_ASSETS_CDN }${ url }`;

export const getRoleFromStoreUser = user => user?.userData?.role?.type;

export const getNameFromStoreUser = user => user?.userData?.user_name + ' ' + user?.userData?.user_surname;

export const getUserIDFromStoreUSer = user => user?.userData?.id;

export const getRequestorName = po_issuer => {
	return po_issuer?.user_name + ' ' + po_issuer?.user_surname;
};

export const getLastStatusFromPO = (order, status_changes)  => {
	switch(order) {
		case 'asc': {
			return _.replace(status_changes[0]?.change_newstatus, /_/g, ' ');
		}
		case 'desc': {
			return _.replace(status_changes[status_changes.length - 1]?.change_newstatus, /_/g, ' ');
		}
	}
};

export const formatStatus = status => {
	return _.replace(status, /_/g, ' ');
};

export const createNewSheetAndPopulate = async (sheetName, data, headerValues, sheetTile) => {
	const exportDoc = new GoogleSpreadsheet(sheetName);
	await exportDoc.useServiceAccountAuth({
		client_email: CLIENT_EMAIL,
		private_key: PRIVATE_KEY.replace(/\\n/g, '\n'),
	});
	// loads document properties and worksheets
	await exportDoc.loadInfo();
	const newSheet = await exportDoc.addSheet({
		title: sheetTile,
		headerValues: headerValues,
	});
	// eslint-disable-next-line no-unused-vars
	const moreRows = await newSheet.addRows(data);
};

/* Get search query params */
export const checkForSearchParams = queryParams => {
	if(queryParams?.get('page')) {
		queryParams.delete('page');
	}
	queryParams = queryParams?.toString();
	return queryParams;
};

export const duplicatePO = id => {
	fetch(calls.onRequestorDuplicatePO(API_URL, getAccessToken(), id))
		.then(() => {
			window.location.replace(window.location.origin + window.location.pathname );
		})
		.catch(err => {
			alert('PO could not be duplicated.');
			console.warn(err);
		});
};

export const getSuppliersFromDB = async() => {
	return await fetch(calls.onGetSuppliers(API_URL, getAccessToken()))
		.then(res => {
			return res?.issuers || [];
		})
		.catch(err => {
			console.warn(err);
			return [];
		});
};