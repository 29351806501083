import React, { useEffect, } from 'react';
import { useNavigate, } from 'react-router-dom';
import omit from 'lodash/omit';

function ProtectedRoute(props) {
	let navigate = useNavigate();
	const { Component, } = props;
	const routeProps = omit(props, ['children', 'loggedIn', 'Component']);

	useEffect(()=> {
		const { loggedIn, } = props;
		if (!loggedIn) {
			navigate('/login');
		}
	}, []);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Component { ...routeProps }/>
	);
}

export default ProtectedRoute;