import React, { Component, lazy, Suspense, } from 'react';
import { Route, Routes, } from 'react-router-dom';
import { connect, } from 'react-redux';
import PageLoader from './components/Templates/PageLoader.jsx';
import { validateToken, } from './actions/userActions.js';
import { getAccessToken,} from './utils/StorageAccess.js';
import ProtectedRoute from './components/Templates/ProtectedRoute.jsx';
import RegularRoute from './components/Templates/RegularRoute.jsx';
import { AppContext, } from './AppContext.jsx';
import Login from './components/Globals/Login.jsx';

const Private = lazy(() => import('./components/Routers/Private.jsx'));

class Index extends Component {
	static whyDidYouRender = true;

	constructor(props) {
		super(props);

		this.appContext = {
		};
	}

	componentDidMount() {
		const { dispatch,} = this.props;
		const token = getAccessToken();

		if (token) {
			validateToken(token, dispatch);
		} else {
			dispatch({type: 'RESET_LOGIN',});
		}
	}

	render() {
		const {userData,} = this.props;
		const {isLoggedIn, loggingIn,} = userData;

		return (
			<AppContext.Provider value={ this.appContext }>
				<Suspense fallback={ <PageLoader/> }>
					<Routes>
						{ loggingIn ?
							<Route
								path={ '/*' }
								element={ <RegularRoute Component={ PageLoader }/> } />
							:
							<>
								<Route
									path={ '/login' }
									element={ <RegularRoute Component={ Login }/> } />
								<Route
									path={ '/*' }
									element={ (
										<ProtectedRoute
											loggedIn={ isLoggedIn }
											Component={ Private }/>
									) }/>
							</>
						}
					</Routes>
				</Suspense>
			</AppContext.Provider>
		);
	}
}

const mapStateToProps = state => ( {
	userData: state.user,
} );

export default connect(mapStateToProps)(Index);