import cloneDeep from 'lodash/cloneDeep';

export default function searchReducer(state=cloneDeep(defaultState), action = {}) {
	let newState = cloneDeep(state);
	switch(action.type) {
		case 'ON_GET_REQUESTERS': {
			newState.gettingRequesters = true;
			newState.gettingRequestersError = '';
			return newState;
		}
		case 'ON_GET_REQUESTERS_FULFILLED': {
			const { requesters, } = action.payload;
			newState.gettingRequesters = false;
			newState.requesters = requesters;
			return newState;
		}
		case 'ON_GET_REQUESTERS_REJECTED': {
			const { error, } = action.payload;
			newState.gettingRequesters = false;
			newState.gettingRequestersError = error;
			return newState;
		}
		case 'RESET_EVERYTHING': {
			return cloneDeep(defaultState);
		}
		default:
			return state;
	}
}

const defaultState = {
	requesters: [],
	gettingRequesters: false,
	gettingRequestersError: '',
};